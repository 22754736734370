import { Modal } from "bootstrap";

export function useAlertas() {
    const modalCirculoCarregando = new Modal(
        document.getElementById("modal-circulo-carregando")
    );
    function mostraTelaCarregando() {
        modalCirculoCarregando.show();
    }
    function escondeTelaCarregando() {
        modalCirculoCarregando.hide();
    }

    return { mostraTelaCarregando, escondeTelaCarregando, };
}
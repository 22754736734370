<template>
  <div>
    <FormularioPedido
      titulo="Meu pedido"
      :submete="enviaPedido"
      :somente-leitura="false"
    />
    <Rodape />
  </div>
</template>

<script>
import FormularioPedido from "@/components/loja/FormularioPedido.vue";
import Rodape from "@/components/shared/Rodape.vue";

import { computed } from "@vue/reactivity";
import { DETALHA_PEDIDO } from "@/store/mutacoes.js";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useApi } from "@/composables/useApi";
import { useToast } from "vue-toastification";
import { useAlertas } from "@/composables/useAlertas";

export default {
  name: "NovoPedido",
  props: {
    id: { type: String, required: true },
  },
  components: {
    FormularioPedido,
    Rodape,
  },
  setup() {
    const store = useStore();
    const loja = computed(() => store.state.loja);
    const pedido = computed(() => store.state.pedido);
    const { criaPedido } = useApi();

    useHead({
      title: computed(
        () => `${loja.value.nome} | Novo pedido | Loja MEI Grátis`
      ),
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
      ],
    });

    const alerta = useToast();
    const { mostraTelaCarregando, escondeTelaCarregando } = useAlertas();
    return {
      pedido,
      criaPedido,
      alerta,
      mostraTelaCarregando,
      escondeTelaCarregando,
    };
  },
  async beforeRouteEnter(origem, destino, proximo) {
    proximo((vm) => {
      if (vm.pedido.itens.length === 0) vm.$router.replace({ name: "loja" });
    });
  },
  methods: {
    enviaPedido: function (formulario) {
      this.mostraTelaCarregando();
      this.criaPedido(this.id, { ...formulario, itens: this.pedido.itens })
        .then((pedido) => {
          this.$store.commit(DETALHA_PEDIDO, {
            id: Number(pedido.id),
            ...formulario,
          });
          this.alerta.success(`Pedido ${pedido.id} foi realizado com sucesso!`);
          this.$router.replace({ name: "pedido-realizado" });
        })
        .catch(() =>
          this.alerta.error(
            "Ocorreu um erro nos nossos servidores.\nPor favor, tente novamente em instantes."
          )
        )
        .finally(() => this.escondeTelaCarregando());
    },
  },
};
</script>
